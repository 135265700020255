<template>
  <div>
    <ListHeader entity-name="serviceProvider" router-name="ServiceProviderNew"></ListHeader>
    <Modal model="serviceProvider" @confirm-deletion="deleteRecord"/>
    <div class="default-according style-1 faq-accordion default-accordion" id="accordionoc">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 xl-30">
            <FilterCard :search-fields="searchFields"
                        :submit-button="searchButton"
                        :tags-checklist=true
                        @searchRecords="search">
            </FilterCard>
          </div>
          <div class="col-xl-9 xl-80">
            <b-card class="m-b-20" no-body v-for="(serviceProvider, id) in records.items" :key="id">
              <CardHeader :id="id" model-name="ServiceProvider" :record="serviceProvider"
                          @sendRecord="sendRecord"></CardHeader>
              <b-collapse :id="`${id}`" role="tabpanel">
                <b-card-body class="pre">
                  <div class="row">
                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.serviceProvider.attributes.email') }}</strong>
                    </p>
                    <p class="col-9 mb-2" v-text="serviceProvider.email"></p>

                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.serviceProvider.attributes.phoneNumber') }}</strong>
                    </p>
                    <p class="col-9 mb-2" v-text="serviceProvider.phoneNo"></p>

                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.serviceProvider.attributes.address') }}</strong>
                    </p>
                    <p class="col-9 mb-2" v-text="serviceProvider.address"></p>

                    <p class="col-3 p-r-0 mb-2" v-if="locationExists(serviceProvider)">
                      <strong>{{ $t('models.serviceProvider.attributes.location') }}</strong>
                    </p>
                    <p class="col-9 mb-2" v-if="locationExists(serviceProvider)">
                      <a class="col-9 mb-2" :href="mapsLink(serviceProvider)" target="_blank">Open in google maps</a>
                    </p>

                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.serviceProvider.attributes.description') }}</strong>
                    </p>
                    <p class="col-9 mb-2" v-text="serviceProvider.truncated_description"></p>

                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.serviceProvider.attributes.category') }}</strong>
                    </p>
                    <p class="col-9 mb-2" v-text="serviceProvider.tagList[0]"></p>

                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.systemFeedback.attributes.rating') }}</strong>
                    </p>
                    <div>
                      <span class="col-9 mb-2" v-for="n in 5" :key="n">
                        <i class="fa fa-star"
                          :class="`${serviceProvider.overallRating >= n ? 'font-warning' : 'font-warning-o'}`">
                        </i>
                      </span>
                    </div>
                  </div>

                  <b-row>
                    <b-col cols="9"></b-col>
                    <b-col>
                      <button 
                        type="submit"
                        class='btn btn-primary btn-blockd text-center' 
                        @click="viewFeedbacks(serviceProvider.id)"
                      >
                        {{ $t('views.constants.reviews') }}
                      </button>
                    </b-col>
                  </b-row>

                </b-card-body>
              </b-collapse>
            </b-card>
            <div class="pagination" v-if="countNotZero">
              <pagination :options="options" :pages="pages" @paginate="paginate"/>
            </div>
            <div v-else>
              {{ $t('messages.error.noContent') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ListsMixin from 'mixins/ListsMixin';

export default {
  name: 'ServiceProviderList',
  data() {
    return {
      modelName: 'serviceProvider',
      searchFields: { tags: [] }
    };
  },
  computed: {
    ...mapGetters({
      records: 'serviceProviders',
      tagsList: 'tagsList'
    })
  },
  methods: {
    ...mapActions({
      getRecords: 'getServiceProviders',
      destroyRecord: 'destroyServiceProvider',
      searchTags: 'searchTags'
    }),
    getFilters(data) {
      let filtersArray = [];
      this._.forEach(data, (value, tag) => {
        if (value) {
          filtersArray.push(tag);
        }
      });

      return { tags: filtersArray };
    },
    mapsLink(record) {
      return 'https://www.google.com/maps/search/?api=1&query=' + record.latitude + ',' + record.longitude;
    },
    locationExists(record) {
      return record.latitude !== null && record.longitude != null;
    },
    viewFeedbacks(id) {
      this.$router.push({ name: 'serviceProviderFeedbacks', params: { id: id } });
    }
  },
  created() {
    this.searchTags({filterBy: { key: '', model: 'ConsultingService' }}).then((response) => {
      this.tagsList.items.map((tag) => this.searchFields['tags'].push(
        {
          type: 'check',
          key: tag,
          value: '',
          searchData: [],
          label: tag,
          valid: true,
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0'
        }
      ));
    });
  },
  mixins: [ListsMixin]
};
</script>
